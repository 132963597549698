<template>
    <b-modal id="addpaymentmodal" modal-class="modal-right-pane">
        <template v-slot:modal-header="{ close }">
            <h2>{{ $t('add_payment') }}</h2>
            <a class="pointer position-relative modal-close" @click="close()">
                <CloseSvg/>
            </a>
        </template>
        <div>
            <h3>{{ $t('sum') }}</h3>
            <InputGroup :label="$t('sum')" class="default" type="number" v-model="form.amount"/>
            <h3>{{ $t('add_student') }}</h3>
            <StudentSearch @selected="studentsSelected"
                           :onlyactive="true"
                           :dropdown="true"
                           :passedstudents="passedstudents ? passedstudents : []"
                           :passedgroups="[]"
                           :showgroups="true"
                           :grouptostudents="true"
            />
            <h3>{{ $t('comment') }}</h3>
            <AutosizeTextArea class="form-control-white"
                              :minH="100"
                              :maxH="200"
                              :white="true"
                              v-model="form.comment">
            </AutosizeTextArea>
        </div>
        <template v-slot:modal-footer>
<!--            <b-button v-if="course" @click="destroy" class="btn-themed btn-white"><TrashSvg/></b-button>-->
            <b-button class="btn-themed" :disabled="!valid" @click="save">{{ $t('save') }}</b-button>
        </template>
    </b-modal>
</template>

<script>
    import CloseSvg from '@/assets/svg/close.svg'
    import InputGroup from "@/views/parts/general/form/InputGroup"
    import { PaymentService } from '@/services/api.service'
    import StudentSearch from '@/views/parts/class/StudentSearch'
    import AutosizeTextArea from "@/views/parts/general/form/AutosizeTextArea"

    export default {
        components: {
            AutosizeTextArea,
            CloseSvg,
            InputGroup,
            StudentSearch
        },
        data() {
            return {
                form: {
                    amount: 0,
                    students: [],
                    group: null,
                    comment: null
                },
            }
        },
        props: {
            passedstudents: {},
            passedgroups: {},
        },
        mounted() {
        },
        computed: {
            valid() {
                return this.form.amount > 0 && this.form.students.length > 0
            }
        },
        methods: {
            save() {

                PaymentService.add(this.form).then(res => {
                    console.log(res)
                    // this.currentCourse = res.data.data
                    this.$emit('refresh')
                    this.$bvModal.hide('addpaymentmodal')
                })

            },
            studentsSelected(data, type = 'students') {
                if(type == 'students') {
                    this.form.students = data.map(a => a.id)
                    this.form.group = null
                }
                if(type == 'groups') {
                    this.form.group = data.length > 0 ? data[0].id : null
                    this.form.students = []
                }
            },
            destroy() {
                // PaymentService.delete(this.course.id).then(() => {
                //     this.$success(this.$t('course_deleted'))
                //     this.$router.push({ name: 'Courses' })
                //     this.$bvModal.hide('addcoursemodal')
                //     this.$emit('reload')
                // })
            },
        }
    }
</script>

<style scoped lang="scss">

</style>


