<template>
  <div>
    <div v-if="showgroups" class="tabs-navigator">
      <a :class="{ active: searchmode === 'students' }" @click="searchmode = 'students'">{{ $t('students') }}</a>
      <a :class="{ active: searchmode === 'groups' }" @click="searchmode = 'groups'">{{ $t('groups') }}</a>
    </div>
    <div class="position-relative">
      <div class="search-input-wrapper" v-if="editable">
        <SearchSvg class="position-absolute search-icon" @click="search"/>
        <input class="search-form-control"
               @blur="showDropdown = false"
               @focus="initFocus"
               :placeholder="placeholder ? placeholder : (searchmode === 'groups' ? $t('groups') : $t('students'))"
               v-model="q"/>
      </div>
      <div class="mt-4">
        <div v-if="searchmode === 'students' || searchmode === 'teachers'">
          <div v-for="(f, sind) in selectedStudents" :key="'selected-student-'+sind"
               class="position-relative student-selected">
            <b-row class="align-items-center">
              <b-col cols="auto">
                <LetterIcon :obj="f" :title="f.name" class="student-icon sm"/>
              </b-col>
              <b-col>
                <h5>{{ f.name }}</h5>
                <p class="text-muted small">{{ f.level }}</p>
              </b-col>
              <b-col cols="auto" v-if="editable">
                <CloseSvg class="student-remove" @click="removeStudent(sind)"/>
              </b-col>
            </b-row>
          </div>
        </div>
        <div v-if="searchmode === 'groups'">
          <div v-for="(f, sind) in selectedGroups" :key="'selected-group-'+sind"
               class="position-relative student-selected">
            <b-row class="align-items-center">
              <b-col>
                <h5>{{ f.title }}</h5>
              </b-col>
              <b-col cols="auto">
                <CloseSvg class="student-remove" @click="removeGroup(sind)"/>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="search-block space-dropdown" v-if="dropdown && showDropdown" v-click-outside="clickoutside">
        <div v-for="(f, find) in foundStudents" :key="'found-student-'+find"
             @mousedown="selectStudent(f)"
             class="position-relative student-searched">
          <b-row class="align-items-center" v-if="!selectedIds.includes(f.id)">
            <b-col cols="auto">
              <LetterIcon :obj="f" :title="f.name" class="student-icon sm"/>
              <!--                            <img class="student-icon sm" :src="f.image"/>-->
            </b-col>
            <b-col>
              <h5>{{ f.name }}</h5>
              <p class="text-muted small">{{ f.level }}</p>
            </b-col>
          </b-row>
        </div>
        <div v-for="(f, find) in foundGroups" :key="'found-group-'+find"
             @mousedown="selectGroup(f)"
             class="position-relative student-searched">
          <h5  v-if="!selectedIds.includes(f.id)">{{ f.title }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchSvg from '@/assets/svg/search.svg'
import CloseSvg from '@/assets/svg/close.svg'
import LetterIcon from '@/views/parts/general/LetterIcon'
import { StudentsService, TeachersService } from '@/services/api.service'

export default {
  components: {
    SearchSvg, CloseSvg, LetterIcon
  },
  data() {
    return {
      q: '',
      foundStudents: [],
      foundGroups: [],
      selectedStudents: [],
      selectedGroups: [],
      students: [],
      searchmode: this.initMode,
      showDropdown: false,
      timer: null
    }
  },
  props: {
    passedstudents: { type: Array },
    passedgroups: { type: Array },
    placeholder: { type: String, default: null },
    dropdown: { type: Boolean },
    showwithoutselection: { type: Boolean },
    editable: { type: Boolean, default: true },
    showgroups: { type: Boolean, default: false },
    grouptostudents: { type: Boolean, default: false },
    initMode: { type: String, default: "students" },
    onlyactive: { type: Boolean },
    onlyone: { type: Boolean, default: false },
    teacher: { type: Number, default: null },
    filter: { type: String, default: "all" },
  },
  methods: {
    search() {
      if(!this.q) {
        // this.found = []
        // this.foundGroups = []
        // if(this.showwithoutselection) {
        //     this.$emit('initialfindings', null)
        // }
        // return
      }
      if(this.searchmode === 'students') {
        StudentsService.get({ q: this.q, onlyactive: this.onlyactive, teacher: this.teacher }).then(res => {
          this.foundStudents = res.data.data.data
          this.foundGroups = []
          if(this.showwithoutselection) {
            this.$emit('initialfindings', this.foundStudents)
          }
        })
      }
      if(this.searchmode === 'groups') {
        StudentsService.getGroups({ q: this.q, onlyactive: this.onlyactive, teacher: this.teacher }).then(res => {
          this.foundStudents = []
          this.foundGroups = res.data.data.data
          if(this.showwithoutselection) {
            this.$emit('initialfindings', this.foundGroups)
          }
        })
      }
      if(this.searchmode === 'teachers') {
        TeachersService.get({ q: this.q, onlyactive: this.onlyactive, role: this.filter }).then(res => {
          this.foundStudents = res.data.data.data
          this.foundGroups = []
          if(this.showwithoutselection) {
            this.$emit('initialfindings', this.foundStudents)
          }
        })
      }

      // this.found = this.students.filter(el => el.name.includes(this.q))
    },
    selectStudent(student) {
      if(this.onlyone) {
        this.selectedStudents = [student]
      } else {
        this.selectedStudents.push(student)
      }
      this.selectedGroups = []
      this.$emit('selected',this.selectedStudents, 'students')
      this.q = ""
    },
    selectGroup(group) {
      if(this.grouptostudents) {
        this.selectedGroups = []
        this.selectedStudents = group.students
        this.$emit('selected',this.selectedStudents, 'students')
        this.searchmode = 'students'
      } else {
        this.selectedGroups = [group]
        this.selectedStudents = []
        this.$emit('selected',this.selectedGroups, 'groups')
      }

      this.q = ""
    },
    removeStudent(index) {
      this.selectedStudents.splice(index, 1)
      this.$emit('selected', this.selectedStudents, 'students')
    },
    removeGroup(index) {
      this.selectedGroups.splice(index, 1)
      this.$emit('selected', this.selectedGroups, 'groups')
    },
    clickoutside() {
      this.q = ""
      // this.showDropdown = false
    },
    initFocus() {
      if(this.dropdown) {
        this.showDropdown = true
        this.search()
      }
    }
  },
  watch: {
    q() {
      if(this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.search()
      }, 250)
    }
  },
  mounted() {
    if (this.passedstudents && this.passedstudents.length > 0) {
      StudentsService.get({ q: this.q, ids: this.passedstudents }).then(res => {
        this.selectedStudents = res.data.data.data
        this.searchmode = 'students'
        this.$emit('selected',this.selectedStudents, 'students')
      })
    }
    if (this.passedgroups && this.passedgroups.length > 0) {
      StudentsService.getGroups({ q: this.q, ids: this.passedgroups }).then(res => {
        this.selectedGroups = res.data.data.data
        this.searchmode = 'groups'
        this.$emit('selected',this.selectedGroups, 'groups')
      })
    }
  },
  computed: {
    selectedStudentsIds() {
      return this.selectedStudents.map(a => a.id)
    },
    selectedGroupsIds() {
      return this.selectedGroups.map(a => a.id)
    },
    selectedIds() {
      return this.searchmode === 'groups' ? this.selectedGroupsIds : this.selectedStudentsIds
    }
  }

}
</script>

<style scoped lang="scss">

h5 {
  margin-bottom: 3px;
}
.student-remove {
  cursor: pointer;
  width: 28px;
  height: 28px;
}
p {
  margin-bottom: 0;
}
.student-searched {
  margin-bottom: 20px;
  cursor: pointer;
  &:last-of-type {
    margin-bottom: 0;
  }
}
.student-selected {
  background: #fff;
  padding: 8px 16px;
  box-sizing: border-box;
  /*box-shadow: 0px 4px 4px rgba(105, 114, 121, 0.25);*/
  border-radius: 16px;
  margin-bottom: 20px;
  &:hover {
    background: $almost-white;
  }
}
.search-block {
  position: absolute;
  top: 50px;
  z-index: 5;
  width: 100%;
  background: #fff;
  padding: 10px 0;
  .student-searched {
    padding: 0 15px;
  }
}
</style>






