<template>
    <section>
        <a class="btn-whole-add btn-whole-add-dashed" @click="addPayment">
            <span class="svg-wrapper mr-3"><PlusSvg class="svg-icon-sm"/></span>{{ $t('add_payment') }}
        </a>
        <PaymentTeaser v-for="(p, pind) in student.student_payments"
                       :payment="p"
                       :student="student"
                       :readonly="false"
                       @refresh="refresh"
                       :key="'payment-'+pind"/>
        <AddPaymentModal :passedstudents="[student.id]" @refresh="refresh"/>
    </section>
</template>

<script>
    import PaymentTeaser from "@/views/teasers/PaymentTeaser"
    import AddPaymentModal from "@/views/pages/instructor/payments/AddPaymentModal"
    import PlusSvg from '@/assets/svg/plus.svg'
    // import AuthService from "@/services/auth.helper"

    export default {
        components: { PaymentTeaser, PlusSvg, AddPaymentModal },
        data() {
            return {
            }
        },
        props: {
            student: {}
        },
        mounted() {
        },
        methods: {
            refresh() {
                this.$emit('refresh')
            },
            addPayment() {
                this.$bvModal.show('addpaymentmodal')
            }

        }
    }
</script>


