<template>
    <div class="payment-teaser">
        <b-row class="align-items-center">
            <b-col lg="auto" cols="auto">
                <LetterIcon :obj="student ? student : payment.student" :title="student ? student.name : (payment.student ? payment.student.name : '-')" class="student-icon md"/>
            </b-col>
            <b-col col>
                <div class="pl-3">
                    <router-link v-if="!readonly" :to="{ name: 'StudentHistory', params: { id: payment.student_id } }">
                        <h3 class="mb-0">{{ student ? student.name : payment.student.name }}</h3>
                    </router-link>
                    <h3 v-else class="mb-0">{{ student ? student.name : payment.student.name }}</h3>
                </div>
            </b-col>
            <div class="w-100 d-block d-md-none"></div>
            <b-col lg="2" cols="6" class="mt-md-0 mt-3">
                {{ payment.created_at | moment('DD MMM YYYY') }}
            </b-col>
            <b-col lg="2" cols="12">
                <div v-if="$store.state.user.primary_role_id == 4">
                    {{ payment.instructor_id ? $t('teacher') : $t('school') }}
                </div>
                {{ payment.comment }}
            </b-col>
            <b-col lg="2" cols="6">
                {{ payment.amount | formatNumber }} {{ student ? student.currency : payment.student.currency }}
            </b-col>
            <b-col lg="auto" cols="6" v-if="!readonly">
                <div class="text-right">
                    <b-dropdown right no-caret menu-class="noscrollbar" variant="link" class="d-inline-block pointer">
                        <template v-slot:button-content>
                            <MoreSvg class="d-inline-block"/>
                        </template>
                        <b-dropdown-item @click="destroy">{{ $t('delete') }}</b-dropdown-item>
                    </b-dropdown>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import MoreSvg from '@/assets/svg/more.svg'
    import { PaymentService } from '@/services/api.service'
    import LetterIcon from '@/views/parts/general/LetterIcon'


    export default {
        components: {
            MoreSvg, LetterIcon
        },
        data() {
            return {

            }
        },
        props: {
            readonly: { type: Boolean },
            payment: {},
            student: {}
        },
        methods: {
            destroy() {
                this.$confirm(this.$t('are_you_sure_you_want_to_delete')).then(() => {
                    PaymentService.delete(this.payment.id).then(() => {
                        this.$bvModal.hide('addpaymentmodal')
                        this.$success(this.$t('payment_deleted'))
                        this.$emit('refresh')
                    })
                })
            }
            // editStudent() {
            //     this.$emit('edit', this.student)
            // },
            // addPayment() {
            //
            // }
        },
        watch: {

        },
        mounted() {

        },

    }
</script>

<style scoped lang="scss">
    .payment-teaser {
        .student-icon {
            width: 60px;
            height: 60px;
        }
        font-size: 14px;
        padding: 24px 0;
        border-bottom: 1px solid $border-gray;
        &:last-of-type {
            border-bottom: none;
        }
    }
</style>






